<template>
  <a
      :href="whatsappLink"
      v-if="isShow"
      type="button"
      :title="$t('widgets.wp.buy-via-whatsapp')"
      class="btn text-whatsapp-dark"
  >
    <WhatsAppIcon class="h-4 w-4 mr-2"/>
    {{ $t('widgets.wp.buy-via-whatsapp') }}
  </a>
</template>

<script setup>
import collect from "collect.js";
import WhatsAppIcon from "~/assets/icons/whatsapp.svg"

const storeConfig = useStoreConfig()
const { whatsAppConfig } = storeToRefs(storeConfig)

const config = computed(() => whatsAppConfig.value?.config)
const phoneNumber = computed(() => collect(config.value).where('labelKey', '=', 'phoneNumber').pluck('value').first())
const isShow = computed(() => parseFloat(collect(config.value).where('labelKey', '=', 'showBuyViaWhatsapp').pluck('value').first()))

const productLink = useState('shareProductLink')
const message = computed(() => getLocaleString(collect(config.value).where('labelKey', '=', 'messageContent').pluck('value').first()))
const whatsappLink = computed(() => {
    if (typeof productLink.value !== 'undefined') {
        return `https://api.whatsapp.com/send?phone=${unref(phoneNumber)}&text=${unref(message)} ${productLink.value}`
    } else {
        return `https://api.whatsapp.com/send?phone=${unref(phoneNumber)}&text=${unref(message)}`
    }
})

</script>

<style scoped>

</style>